import React from "react";
import SettingsPage from "./settings";
import AdminAccountsContainer from "pages/admin/admin-accounts/admin-accounts-container";
import TelegramContainer from "./pages/telegram/telegram-container";
import SettingsMenuContainer from "./menu/settings-menu-container";
import TopicsContainer from "./pages/topics/topics-container";
import UsersContainer from "./pages/users/users-container";
import DigestContainer from "./pages/digest/digest-container";
import ReportsContainer from "./pages/reports/reports-container";
import FiltersContainer from "./pages/filters/filters-container";
import TagsContainer from "./pages/tags/tags-container";

class SettingsController extends React.Component{

    constructor(props){
        super(props);

        this.selected = 2;
        this.state = ({
            selectedLanguage: this.props.selectedLanguage,
            selectedRole: this.props.selectedRole
        })
    }

    getComponent = (menuId) => {
        switch(menuId){
            case 0: {
                return <TopicsContainer/>
            }
            case 1: {
                return <AdminAccountsContainer />
            }
            case 2: {
                return <DigestContainer/>
            }
            case 3: {
                return <ReportsContainer />
            }
            case 5: {
                return <TelegramContainer />
            }
            case 6: {
                return <FiltersContainer />
            }
            case 9: {
                return <TagsContainer />
            }
            default:
                return <div></div>
        }

    }

    getMenuItems = () => {
        let menu = [...this.props.menuItems]
        if(this.props.selectedRole === 'ROLE_ADMIN'){
            
            menu[1].show = true
        }
        else{
            //let menu = [...this.props.menuItems]
            menu[1].show = false
            
        }
        return menu
    }
    
    componentDidMount(){
        this.props.setComponent(this.getComponent(this.selected));
        this.props.setShowLeftMenu(true);
        let m = this.getMenuItems()
        //this.props.setMenus(m);  
        this.props.setPageName('Settings')
        this.props.setLeftMenuComponent(<SettingsMenuContainer items={m} adminMode={true}/>, 'settingsMenu');    
    }

    componentDidUpdate(){
        if(!this.props.component || this.props.selected !== this.selected){
            this.selected = this.props.selected
            
            this.props.setComponent(this.getComponent(this.props.selected))
        }
        if(this.state.selectedRole !== this.props.selectedRole){
            let m = this.getMenuItems()
            this.props.setLeftMenuComponent(<SettingsMenuContainer items={m} adminMode={true}/>, 'settingsMenu');
            this.setState({
                selectedRole: this.props.selectedRole
            })
        }
    }

    componentWillUnmount(){
        if(this.props.settingMenuToNUll)
            this.props.setSelectedMenu(0)
        this.props.setRemoveLeftMenuComponent(null);
        this.props.setShowLeftMenu(false);
        this.props.setThemeTableData([]);//clear topic
        this.props.setSubtopicItem([]);//clear subtopic
    }

    render () {
        return <SettingsPage {...this.props} />
    }
}

export default SettingsController;
