const HeaderButtons = {
    ShortAnalytic: {
        name: 'ShortAnalytic',
        base_name: 'SHORT_ANALYTICS',
        title: 'Краткая аналитика',
        type: 'nav',
        link: '/page/short-analytic',
        roles: ['ROLE_ADMIN', 'ROLE_MARKER', 'ROLE_USER']
    },
    Sample: {
        name: 'Sample',
        base_name: 'SELECTION',
        title: 'Выборка',
        type: 'nav',
        link: '/page/main',
        roles: ['ROLE_ADMIN', 'ROLE_MARKER', 'ROLE_USER']
    },
    DeployedAnalytic: {
        name: 'DeployedAnalytic',
        base_name: 'EXTENDED_ANALYTICS',
        title: 'Развернутая аналитика',
        type: 'nav',
        link: '/page/analytic',
        roles: ['ROLE_ADMIN', 'ROLE_MARKER', 'ROLE_USER']
    },
    Comparison: {
        name: 'Comparison',
        base_name: 'TOPICS_COMPARISON',
        title: 'Сравнение тем',
        type: 'nav',
        link: '/page/topic-comparison',
        roles: ['ROLE_ADMIN', 'ROLE_MARKER', 'ROLE_USER']
    },
    ReporterConstructor: {
        name: 'ReporterConstructor',
        base_name: 'REPORTS_BUILDER',
        title: 'Конструктор отчетов',
        type: 'nav',
        link: '/page/reporter-constructor'
    },
    AutoRules: {
        name: 'AutoRules',
        base_name: 'AUTO_RULES',
        title: 'Авто правила',
        type: 'nav',
        link: '/page/auto-rules'
    },
    ChosenPublications: {
        name: 'ChosenPublications',
        base_name: 'FAVORITE_POSTS',
        title: 'Избранные публикации',
        type: 'nav',
        link: '/page/favourite-publications',
        roles: ['ROLE_ADMIN', 'ROLE_MARKER', 'ROLE_USER']
    },
    ChosenAuthors: {
        name: 'ChosenAuthors',
        base_name: 'FAVORITE_AUTHORS_AND_SOURCES',
        title: 'Избранные авторы и источники',
        type: 'nav',
        link: '/page/favourite-authors-resources',
        roles: ['ROLE_ADMIN', 'ROLE_MARKER', 'ROLE_USER']
    },
    BlackList: {
        name: 'BlackList',
        base_name: 'BLACK_LIST',
        title: 'Черный список',
        type: 'nav',
        link: '/page/black-list',
        roles: ['ROLE_ADMIN', 'ROLE_MARKER', 'ROLE_USER']
    },
    RecycleBin: {
        name: 'RecycleBin',
        base_name: 'TRASHBIN',
        title: 'Корзина',
        type: 'nav',
        link: '/page/recycle-bin',
        roles: ['ROLE_ADMIN', 'ROLE_MARKER', 'ROLE_USER']
    },
    Languages: {
        name: 'Languages',
        base_name: 'LANGUAGE',
        title: 'Язык',
        type: 'dropdown',
        showInAuth: true,
        roles: ['ROLE_ADMIN', 'ROLE_MARKER', 'ROLE_USER']
    },
    Themes: {
        name: 'Themes',
        base_name: 'TOPIC',
        title: 'Тема',
        type: 'dropdown',
        showInAuth: true,
        roles: ['ROLE_ADMIN', 'ROLE_MARKER', 'ROLE_USER']
    },
    CallUs: {
        name: 'CallUs',
        base_name: 'CONTACT_US',
        title: 'Связаться с нами',
        type: 'modal',
        showInAuth: true,
        roles: ['ROLE_ADMIN', 'ROLE_MARKER', 'ROLE_USER']
    },
    Settings: {
        name: 'Settings',
        base_name: '_COMMENT_17',
        title: 'Настройки',
        type: 'nav',
        link: '/page/settings',
        roles: ['ROLE_ADMIN', 'ROLE_MARKER', 'ROLE_USER']
    },
    Account: {
        name: 'Account',
        base_name: 'ACCOUNT',
        title: 'Account',
        type: 'dropdown',
        showInAuth: false,
        roles: ['ROLE_ADMIN', 'ROLE_MARKER', 'ROLE_USER']
    },
    Exit: {
        name: 'Exit',
        base_name: 'EXIT',
        title: 'Выход',
        type: 'modal',
        buttonsTitle: ['Выйти', 'Отмена'],
        buttonsIcon: ['ok', 'close'],
        modalTitle: 'EXIT_COMPANY',
        modalText: 'SURE_WANT_TO_EXIT',
        roles: ['ROLE_ADMIN', 'ROLE_MARKER', 'ROLE_USER']
    },
    NOTIFICATIONS: {
        name: 'NOTIFICATIONS',
        base_name: 'DOWNLOADS',
        title: 'NOTIFICATIONS',
        type: 'modal',
        showInAuth: true,
        roles: ['ROLE_ADMIN', 'ROLE_MARKER', 'ROLE_USER'] 
    },
    Admin: {
        name: 'Admin',
        base_name: 'ADMIN',
        title: 'Admin',
        type: 'nav',
        link: '/page/admin',
        showInAuth: true,
        roles: ['ROLE_SUPER_ADMIN'] 
    },
    AdminAccounts: {
        name: 'AdminAccounts',
        base_name: 'ACCOUNTS',
        title: 'Admin Accounts',
        type: 'nav',
        link: '/page/admin-accounts',
        showInAuth: true,
        roles: ['ROLE_SUPER_ADMIN'] 
    },
    AdminTopics: {
        name: 'AdminTopics',
        base_name: 'TOPICS',
        title: 'Admin Topics',
        type: 'nav',
        link: '/page/admin-topics',
        showInAuth: true,
        roles: ['ROLE_SUPER_ADMIN'] 
    },
    AdminMarkers: {
        name: 'AdminMarkers',
        base_name: 'MARKERS',
        title: 'Admin Markers',
        type: 'nav',
        link: '/page/admin-markers',
        showInAuth: true,
        roles: ['ROLE_SUPER_ADMIN'] 
    },
    AdminMigration: {
        name: 'AdminMigration',
        base_name: 'HISTORICAL_MIGRATION',
        title: 'Admin Migration',
        type: 'nav',
        link: '/page/admin-migration',
        showInAuth: true,
        roles: ['ROLE_SUPER_ADMIN'] 
    },
    ExitCompany: {
        base_name: 'EXIT_COMPANY', 
        title: 'Exit', 
        name: 'ExitCompany', 
        type: 'modal', 
        modalText: "Exit from comapny", 
        modalTitle: 'Exit Company', 
        buttonsIcon: ['ok', 'close'], 
        buttonsTitle: ['Выйти', 'Отмена'], 
        roles: ['ROLE_ADMIN', 'ROLE_MARKER', 'ROLE_USER']
    }

}
export default HeaderButtons;