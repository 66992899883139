import React from "react";
import Grouping from "./grouping";

class GroupingController extends React.Component{

    constructor(props){
        super(props)
    }

    componentDidMount(){
        if(!this.props.grouping)
            this.props.setGroupingByPeriod(this.props.startDate, this.props.endDate)
    }

    render(){
        return(
            <Grouping isFetching={this.props.isFetching} getValue={this.props.getValue} setGrouping={this.props.setGrouping} startDate={this.props.startDate} endDate={this.props.endDate} grouping={this.props.grouping}/>
        )
        
    }
}

export default GroupingController;