import HeaderButtons from "assets/header-buttons/header-buttons"
import { ReactComponent as UserIcon } from "../assets/icons/user.svg";
import { ReactComponent as ExitIcon } from "../assets/icons/exit.svg";

const CHOOSE_PAGE = "CHOOSE-PAGE";
const SHOW_HIDE_MENU = "SHOW-HIDE-MENU";
const SET_THEME = "SET-THEME";
const SET_SELECTED = "SET-SELECTED";
const SET_SHOW_PANEL = "SET-SHOW-PANEL";
const SET_PAGINATOR = "SET-PAGINATOR";
const SET_SUPERADMIN_MENU_BUTTONS = "SET-SUPERADMIN-MENU-BUTTTONS";
const SET_DEVICE_TYPE = "SET-DEVICE-TYPE";

let headerState = {
    selected: 'short-analytic',
    showMenu: false,
    selectedTheme: 'Echo',
    isShowPanel: false,
    paginator: false,
    deviceType: null,
    AccountItems: 
    [
        {name: "ACCOUNT", short_name: 'ACCOUNT', title: 'ACCOUNT', icon: UserIcon},
        {name: "EXIT", short_name: 'Exit', title: 'EXIT', icon: ExitIcon},
    ],
    topButtons: [
        HeaderButtons.ShortAnalytic,
        HeaderButtons.Sample,
        HeaderButtons.DeployedAnalytic,
        HeaderButtons.Comparison,
        //HeaderButtons.ReporterConstructor,
        //HeaderButtons.AutoRules
    ],
    mediumButtons: [
        HeaderButtons.ChosenPublications,
        HeaderButtons.ChosenAuthors,
        HeaderButtons.BlackList,
        HeaderButtons.RecycleBin
    ],
    rightTopButtons: [
        HeaderButtons.Languages,
        //HeaderButtons.Themes,
        HeaderButtons.CallUs,
        HeaderButtons.Settings,
        HeaderButtons.Account,
        //HeaderButtons.Exit
    ],
    rightBottomButton: [
        HeaderButtons.NOTIFICATIONS
    ],
    adminMediumButtons: [
        //HeaderButtons.Admin,
        HeaderButtons.ChosenPublications,
        HeaderButtons.ChosenAuthors,
        HeaderButtons.BlackList,
        HeaderButtons.RecycleBin
    ],
    superAdminTopButtons: null,
    superAdminMediumButtons: [
        HeaderButtons.Admin,
        HeaderButtons.AdminAccounts,
        HeaderButtons.AdminTopics,
        HeaderButtons.AdminMarkers,
        HeaderButtons.AdminMigration
    ],
    superAdminRightTopButtons: [
        HeaderButtons.Languages,
        HeaderButtons.Exit
    ],
    superAdminRightBottomButtons: null,
    markerTopButtons: [
        
    ],
    markerMediumButtons: [
        HeaderButtons.Sample,
        HeaderButtons.BlackList,
        HeaderButtons.RecycleBin
    ],
    markerRightTopButtons: [
        HeaderButtons.Languages,
        HeaderButtons.Exit
    ],
    defaultRightTopButtons: [
        HeaderButtons.Languages,
    ]
}

let header = (state = {...headerState}, action) =>{
    let newState = {...state}
    switch(action.type){
        case CHOOSE_PAGE:
            return {...state, selected: action.selected};
        case SHOW_HIDE_MENU:
            return {...state, showMenu: action.showMenu}
        case SET_SELECTED:
            return {...state, selected: action.selected}
        case SET_SHOW_PANEL:
            return {...state, isShowPanel: action.isShowPanel, paginator: action.paginator}
        case SET_SUPERADMIN_MENU_BUTTONS:
            let topButtons = [];
            let rightTopButtons = [];
            let rightBottomButtons = [];
            let mediumButtons = [];
            if(action.setSuperadminTopButtons){
                topButtons = topButtons.concat(state.topButtons);
                mediumButtons = [...headerState.mediumButtons]
                rightTopButtons = [...headerState.rightTopButtons];
                rightTopButtons.unshift(HeaderButtons.ExitCompany)
                //mediumButtons = mediumButtons.concat(state.mediumButtons)
                rightBottomButtons = [...headerState.rightBottomButton]
                //mediumButtons = mediumButtons.filter(k => {{return k.name !== 'AdminAccounts' && k.name !== 'AdminMarkers' && k.name !== 'AdminTopics' && k.name !== 'AdminMigration' && k.name !== 'Admin'}})
            }
            else{
                rightTopButtons = headerState.superAdminRightTopButtons;
                mediumButtons = headerState.superAdminMediumButtons;
            }
            return {...state, superAdminTopButtons: topButtons, superAdminRightTopButtons: rightTopButtons, superAdminMediumButtons: mediumButtons, superAdminRightBottomButtons: rightBottomButtons}
        case SET_DEVICE_TYPE:
            return {...state, deviceType: action.deviceType}
            default:
            return newState;
    }
}

export default header;

export const setDeviceType = (device) => {
    return {
        type: SET_DEVICE_TYPE,
        deviceType: device
    }
}

export let showHideMenu = (showMenu) => {
    return {
        type: SHOW_HIDE_MENU,
        showMenu: showMenu
    }
}

export const setButtons = (setSuperadminTopButtons) => {
    return {
        type: SET_SUPERADMIN_MENU_BUTTONS,
        topButtons: [],
        setSuperadminTopButtons: setSuperadminTopButtons
    }
}

export const setSuperadminMenuButtons = (setSuperadminTopButtons) => {
    return (dispatch) => {
        dispatch(setButtons(setSuperadminTopButtons));
        dispatch(selectPage('ShortAnalytic'))
    }
}

export const setTheme = () => {
    return {
        type: SET_THEME,

    }
}

export let exitAuth = () => {

}

export let selectPage = (selected) =>{
    return {
        type: CHOOSE_PAGE,
        selected: selected
    }
}

export const setShowPanel = (showPanel, showPaginator) => {
    return {
        type: SET_SHOW_PANEL,
        isShowPanel: showPanel,
        paginator: showPaginator
    }
}