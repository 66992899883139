import { applyMiddleware, combineReducers } from 'redux';
import { legacy_createStore as createStore} from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk';
import authorization from './authorization';
import Authorities from './authorities';
import Account from './account';
import header from './header';
import signinForm from './signin';
import Modal from './modal';
import SideBarMenu from './side-bar';
import languages from './languages';
import mainThemes from './main-theme';
import themesPage from './theme';
import settingsPage from './settings';
import SelectDropdownWithSearch from './select-dropdown';
import table from './table';
import settingsPageMenu from './settings-menu';
import themeSettings from './theme-settings';
import usersSettings from './users-settings';
import Keywords from './keywords';
import TopicItem from './topic-item';
import ErrorHandler from './errors';
import storage from './storage';
import Tree from './tree';
import digest from './digest';
import ShortAnalytic from './short-analytic';
import ExtendedAnalytic from './extended-analytic';
import Newsbreaks from './newsbreaks';
import Calendar from "./calendar";
import Tags from './tags';
import Filters from './filters';
import paginator from './paginator';
import notificationsStatuses from './notificationsStatuses';
import report from './report';
import telegram from './telegram';
import FavouriteList from './favourite-list';
import BlackList from './black-list';
import geoplace from './geoplace';
import superadmin from './superadmin';
import admin from './admin';
import reportSettings from "./report-settings";
import filtersSettings from './filters-settings';
import AdminTopics from './admin-topics';
import AdminMarkers from './admin-markers';
import TopicSelectItems from './topics-select';
//import Analytic from './analytic';
import SearchText from "./search-text";
import ReturnBack from "./return-back-button";
import Migration from './migration';
import AdminMode from './switch-admin-mode';
import AdminReports from './admin-reports';
import Comparison from './comparison';
import TopicMenu from './topic-menu';

const RESET_STORE = "RESET-STORE";

let reducers = combineReducers({
    authorization,
    Authorities,
    header,
    signinForm,
    Modal,
    SideBarMenu,
    Account,
    languages,
    mainThemes,
    themesPage,
    settingsPage,
    SelectDropdownWithSearch,
    table,
    settingsPageMenu,
    themeSettings,
    usersSettings,
    Keywords,
    ErrorHandler,
    TopicItem,
    storage,
    Tree,
    digest,
    ShortAnalytic,
    ExtendedAnalytic,
    Newsbreaks,
    Calendar,
    Tags,
    Filters,
    paginator,
    notificationsStatuses,
    report,
    telegram,
    FavouriteList,
    BlackList,
    geoplace,
    superadmin,
    admin,
    reportSettings,
    filtersSettings,
    AdminTopics,
    AdminMarkers,
    TopicSelectItems,
    //Analytic,
    SearchText,
    ReturnBack,
    Migration,
    AdminMode,
    AdminReports,
    Comparison,
    TopicMenu
});

export const rootReducers = (state, action) => {
    if (action.type === RESET_STORE){
        return reducers(combineReducers(null), action)
    }
    return reducers(combineReducers(null), action)
}

export const resetStore = () => {
    return {
        type: RESET_STORE
    }
}

let store = createStore(reducers, applyMiddleware(thunkMiddleware));



export default store;