import ExtendedAnalyticController from "./extended-analytic-controller";
import { withAuthRedirect } from "hoc/AuthRedirect";
import { connect } from "react-redux";
import { compose } from "redux";
import { setShowPanel } from "store/header"
import { getValue } from "store/languages";
import { setIsFilterChanged, getCompanyTags, getTopicsFilters, getBlackListFilters, setSelectedTopics, setPageName } from "store/filters";
import { setLeftMenuComponent, setRemoveLeftMenuComponent, setRightMenuComponent, setRemoveRightMenuComponent, setShowLeftMenu, setShowRightMenu } from 'store/side-bar';
import { setMenus } from "store/settings-menu";
import { getStartDate, getEndDate } from "selectors/calendar-selector";
import { setGrouping, getGroupingByPeriod, setStartDate, setEndDate } from "store/calendar";
import {    setClearData,
            getDataByType,
            setStarted,
            setAnalyticIsFetching
        } from "store/extended-analytic"
import { getTopics, getSubtopicsFromState } from "selectors/theme-selector";
import { getMyTopics, setThemeTableData } from "store/theme-settings";

import { setThemes } from "store/theme";

import { setSelectedTopicsItems } from "store/topics-select"
import { getSubtopics, setSubtopicsUpdated, setSubtopicItem, setSubtopics } from "../../store/topic-item"
import { clearSearchList } from 'store/search-text';
import { setIsReturnedBack, setReturnBackPostCount } from "store/return-back-button";
import { getExtendedData, getExtendedDataSecond, getExtendedDataS, getExtendedThird } from "selectors/extended-selector";

let mapStateToProps = (state) => {

    return {
        selectedLanguage: state.languages.selectedLanguage,
        startDate: getStartDate(state),
        endDate: getEndDate(state),
        isChanged: state.Filters.isChanged,
        grouping: state.Calendar.grouping,
        selectedItems: state.Filters.selectedMyFilter,
        selectedItemItems: state.Filters.selectedItemItems,
        showRightMenu: state.SideBarMenu.showRightMenu,
        rightMenuComponent: state.SideBarMenu.rightMenuComponent,
        leftMenuComponent: state.SideBarMenu.leftMenuComponent,
        sideBarUpdated: state.SideBarMenu.sideBarUpdated,
        settingsMenuItems: state.settingsPageMenu.items,
        menuItems: state.ExtendedAnalytic.menuItems,
        comparisonMenu: state.ExtendedAnalytic.comparisonMenu,
        component: state.ExtendedAnalytic.component,
        selected: state.settingsPageMenu.selectedMenuItem,
        topicData: getTopics(state),
        subtopics: getSubtopicsFromState(state),
        //languageRangeData: getLanguageRange(state),
        selectedTopic: state.Tree.selectedItem,
        selectedId: state.Tree.selectedId,
        selectedUUId: state.Tree.selectedUUId,
        selectedSubId: state.Tree.selectedSubId,
        selectedSubUUId: state.Tree.selectedSubUUId,
        isSubtopicsUpdated: state.TopicItem.isSubtopicsUpdated,

        searchList: state.SearchText.searchList,

        isFetching: state.ExtendedAnalytic.isFetching,
        geoplace: state.geoplace.geoplace,
        tags: state.Filters.tags,
        selectedTopicsItems: state.TopicSelectItems.selectedTopicsItems,
        topicUUId: state.TopicSelectItems.topicUUId,
        selectedType: state.TopicSelectItems.selectedType,
        comparisonUpdated: state.Analytic.comparisonUpdated,

        isFetchingAnalytic: state.ExtendedAnalytic.isFetching,
        first: getExtendedData(state, 'first'),
        second: getExtendedDataS(state, 'second'),
        third: getExtendedThird(state, 'third'),
        started: state.ExtendedAnalytic.started,
        isReturnedBack: state.ReturnBack.isReturnedBack,
        locationsName: state.ExtendedAnalytic.locationsName
    }
}

export default compose(connect(mapStateToProps, {
                                            getValue, 
                                            setRightMenuComponent,
                                            setRemoveRightMenuComponent,
                                            setShowRightMenu, 
                                            setLeftMenuComponent,
                                            setRemoveLeftMenuComponent,
                                            setShowLeftMenu, 
                                            setMenus,
                                            setClearData,
                                            setGrouping,
                                            setShowPanel,
                                            getDataByType,
                                            getMyTopics,
                                            setThemes,
                                            getSubtopics, 
                                            setSubtopicsUpdated,
                                            setIsFilterChanged,
                                            setThemeTableData,
                                            setSubtopicItem,
                                            setSubtopics,
                                            getCompanyTags, 
                                            getTopicsFilters, 
                                            getBlackListFilters,
                                            getGroupingByPeriod,
                                            setStartDate, 
                                            setEndDate,
                                            setSelectedTopicsItems,
                                            setSelectedTopics,
                                            setPageName,
                                            clearSearchList,
                                            setStarted,
                                            setAnalyticIsFetching,
                                            setIsReturnedBack,
                                            setReturnBackPostCount
                                        }),withAuthRedirect)(ExtendedAnalyticController)