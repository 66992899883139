import React, {useState, useEffect} from "react";
import { StandartButton } from "commons/form-controls";
import s from "./groupng.module.scss"
import moment from "moment";

const Grouping = (props) => {

    const getDisableHour = (startDate, endDate) => {
        let st = moment(startDate).add(7, "days")
        let ed = moment(endDate)
        let s = moment(ed).isAfter(st);
        return s
    }

    const [disableHourPeriod, setDisableHourPeriod] = useState(getDisableHour(props.startDate, props.endDate));

    useEffect(() => {
        setDisableHourPeriod(getDisableHour(props.startDate, props.endDate));
    }, [props.startDate, props.endDate, props.grouping, props.isFetching])

    return (
        <div className={s.Grouping}>
            
              <span className={s.labelText}>{ props.getValue('BREAKDOWN_BY') }</span>
              <div className={s.GroupingButtons}>
              <StandartButton background={'white'} selected={props.grouping === 'HOURLY'} disabled={props.isFetching || props.grouping === 'HOURLY' || disableHourPeriod} title={props.getValue('HOURS')} callback={props.setGrouping.bind(this, 'HOURLY')}/>
              <StandartButton background={'white'} selected={props.grouping === 'DAILY'} disabled={props.isFetching || props.grouping === 'DAILY'} title={props.getValue('DAYS')} callback={props.setGrouping.bind(this, 'DAILY')}/>
              <StandartButton background={'white'} selected={props.grouping === 'WEEKLY'} disabled={props.isFetching || props.grouping === 'WEEKLY'} title={props.getValue('WEEKS')} callback={props.setGrouping.bind(this, 'WEEKLY')}/>
              <StandartButton background={'white'} selected={props.grouping === 'MONTHLY'} disabled={props.isFetching || props.grouping === 'MONTHLY'} title={props.getValue('MONTHS')} callback={props.setGrouping.bind(this, 'MONTHLY')}/>
    
              </div>

        </div>
    )
}

export default Grouping;