import PeriodCalendarController from "./period-calendar-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { modalShowToggle } from "store/modal";
import { getStartDate, getEndDate } from "selectors/calendar-selector";
import { setStartDate, setEndDate, setGrouping, setDateChanged, setGroupingByPeriod } from "store/calendar";

let mapStateToProps = (state) => {
    return {
        startDate: getStartDate(state),
        endDate: getEndDate(state),
        grouping: state.Calendar.grouping,
        language: state.languages.selectedLanguage,
        isFetching: state.ExtendedAnalytic.isFetching,
        isComparisonFetching: state.Comparison.isComparisonFetching,
    }
}

const PeriodCalendarContainer = (connect(mapStateToProps, {getValue, modalShowToggle, setStartDate, setEndDate, setGrouping, setGroupingByPeriod})(PeriodCalendarController));

export default PeriodCalendarContainer;