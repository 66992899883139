import React, {useState, useEffect} from "react";
import DefaultDays from "./default-days/default-days";
import GroupingContainer from "./grouping/grouping-container";
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import moment from "moment";
import s from "./period-calendar.module.scss";

const PeriodCalendar = (props) => {

    const [startDateStr, setStartDateStr] = useState(moment(props.startDate).format("DD-MM-YYYY HH:mm"));
    const [enDateStr, setEndDateStr] = useState(moment(props.endDate).format("DD-MM-YYYY HH:mm"));
    const [isFetching, setIsFetching] = useState(props.isFetching || props.isComparisonFetching ? true : false );

    useEffect(()=>{
        setStartDateStr(moment(props.startDate).format("DD-MM-YYYY HH:mm"))
        setEndDateStr(moment(props.endDate).format("DD-MM-YYYY HH:mm"));
        setIsFetching(props.isFetching || props.isComparisonFetching ? true : false )
    }, [props.startDate, props.endDate, props.isFetching, props.isComparisonFetching]);


    return (
        <div className={s.PeriodCalendar}>
            <div className={s.PeriodDisplay} onClick={props.showCalendar}>
                <div className={s.Period}>
                    <span>{startDateStr}</span>
                    <span className={s.Separator}>-</span>
                    <span>{enDateStr}</span>
                </div>
                <CalendarIcon />
            </div>
            <DefaultDays getValue={props.getValue} callback={props.setPeriodDay} isFetching={isFetching}/>
            {props.footerButtons === 'grouping' && <GroupingContainer isFetching={isFetching}/>}
        </div>
    )
}

export default PeriodCalendar;