import React, {useEffect, useState} from "react";
import s from "./settings-menu.module.scss";
import SwitchAdminModeContainer from "./switch-admin-mode/switch-admin-mode-container";
import Bar from "items/preload/bar";

const SettingsMenu = (props) => {
    const [selected, setSelected] = useState(props.startPage ? props.startPage : props.selected);
    const [isFetching, setIsFetching] = useState(props.isFetching || props.isComparisonFetching ? true : false );
    const [isDisabled, setIsDisabled] = useState(false)
    const [rerender, setRerender] = useState(false)

    useEffect(() => {
        setSelected(props.startPage ? props.startPage : props.selected);
        setIsFetching(props.isFetching || props.isComparisonFetching ? true : false );
        if(isDisabled) setTimeout(() => { setIsDisabled(false)}, props.pageName === 'Analytic' ? 1000 : 50)
    }, [props])

    const setMenu = (item) => {
        if((!isFetching || !isDisabled) && selected !== item){
            //setSelected(item)
            setIsDisabled(true)
            
            setRerender(!rerender)
            props.setSelectedMenuItem(item)
        }

    }

    const settingsMenuItems = (props.items && props.items.length) ? props.items.map((item,i)=>{
        return item.show 
                        ? <button disabled={isFetching || isDisabled} className={selected === i ? s.MenuItem+' '+s.activeMenu : s.MenuItem} key={i} onClick={setMenu.bind(this, i)}>
                            <span className={s.itemName}>{props.getValue(item.title)}</span>
                            {(isFetching || isDisabled) && selected === i && (props.pageName === 'Analytic' || props.pageName === "Comparison") ? <div className={s.status}><Bar /></div> : null }
                        </button> : <div key={i}></div>
    }) : []

    return (
        <div className={s.SettingsMenu}>
            {(props.role === 'ROLE_SUPER_ADMIN' || props.role === 'ROLE_ADMIN') && props.adminMode ? <SwitchAdminModeContainer /> : null}
            {settingsMenuItems}
        </div>
    )
}

export default SettingsMenu;